import React from 'react'
import Layout from '../components/layout'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import ConsumerCarousel from '../components/Share/ConsumerCarousel'
import Title from '../components/Share/Title'
import Image from '../components/Lendis/Image'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Über uns',
  },
]

const AboutUs = () => {
  const heroImage2 =
    process.env.CLOUDINARY_URL +
    '/f_auto/website/26b-1920x704-darker-485_Ted_Schreibtisch_Lendis_DSC00153_mhee2a.jpg'
  const heroImage = process.env.CLOUDINARY_URL + '/f_auto/website/2_1920px_DSC5520_kj2hce.jpg'

  return (
    <Layout>
      <Header page="about-us" />
      <Menu />
      <div
        className="big-background-image pb-8  bg-lendis-main bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${heroImage2})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Über uns</h1>

          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">Wir stellen uns vor</p>
        </div>

        <div className="bg-white container mx-auto lg:px-10">
          <BreadCrumb breadCrumbItems={breadcrumb} />
          <div className="px-10">
            <Title title="We Power Your Office" header="Über uns" />
          </div>

          <div className="bg-white px-10 pb-10">
            <div className="mt-8 bg-gray-100 px-6 leading-loose pt-20 lg:pt-16 pb-8 lg:flex lg:items-center">
              <div className="lg:flex">
                <Image
                  noChange
                  className="w-32 h-32"
                  src="website/lendis-logo-icon_mq1mwa.png"
                  alt="Houses"
                />
              </div>
              <p className="mt-8 lg:ml-6 lg:text-2xl font-bold">
                Lendis ist Ihr Allround-Partner für alle Aspekte des Office-Managements.
              </p>
            </div>
            <div className="lg:flex lg:pb-6">
              <p className="leading-loose mt-8 lg:mr-8 w-full lg:w-1/2 text-justify">
                Lendis stattet Büros nicht nur umfänglich aus sondern hilft auch dabei, das Office
                langfristig effizient und digital zu managen. Hochwertige Möbel und moderne
                Elektronik können Sie bei uns monatlich mieten, flexibel hinzubuchen und
                austauschen. Damit ermöglichen wir Ihnen, sich auf das Wesentliche zu konzentrieren:
                das Wachstum Ihres Unternehmens.
              </p>
              <p className="leading-loose mt-8 lg:ml-8 w-full lg:w-1/2 text-justify">
                Lendis hilft Ihnen außerdem, das Office effizient und erfolgreich zu managen. Denn
                vorinstallierte Software-Pakete und nützliche Büro-Services wie Internet, Catering
                oder Reinigung können Sie genauso bequem über uns buchen wie attraktive Benefits für
                Ihre Mitarbeiter
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="cover-background-image pb-8 bg-center bg-gray-800 bg-object bg-no-repeat bg-top lg:pb-20 px-3"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="py-32" />
        <div className="bg-white mx-auto container">
          <div className="px-6 pt-12">
            <div className="px-10 ">
              <Title title="Unser Antrieb für Sie" header="Fakten" titleSize="text-3xl" />
            </div>

            <div className="lg:flex lg:flex-wrap lg:w-4/5 mx-auto lg:pb-12">
              <div className="mt-8 bg-gray-100 px-6 leading-loose pt-20 lg:pt-16 pb-8 lg:w-1/3 ">
                <Image noChange src="/website/user_m4pvb5.svg" alt="Customers" />
                <p className="text-xl text-semibold">500+</p>
                <p className="lg:m-0">Kunden vertrauen auf uns</p>
              </div>
              <div className="mt-8 bg-gray-100 px-6 leading-loose bg-gray-900 text-white pt-20 lg:pt-16 pb-8 lg:w-1/3">
                <Image noChange src="/website/furniture_gt5dui.svg" alt="Furniture" />
                <p className="text-xl text-semibold">10.000+</p>
                <p className=" lg:m-0">Elektronik- und Möbelstücke vermietet</p>
              </div>
              <div className="mt-8 bg-lendis-main px-6 leading-loose bg-gray-100 pt-20 lg:pt-16 pb-8 lg:w-1/3">
                <Image src="website/vertical/truck_witawn.png" alt="Delivery" fixSize="51" />
                <p className="text-xl text-semibold">100+</p>
                <p className="mt-8 lg:m-0">Erfolgreiche Lieferungen pro Monat</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConsumerCarousel />
      <Footer />
    </Layout>
  )
}
export default AboutUs
