import React from 'react'
import Carousel from '../Carousel'
import Image from '../Lendis/Image'

const ConsumerCarousel = () => {
  const quote = process.env.CLOUDINARY_URL + '/f_auto/website/q_efdiuy.png'
  return (
    <div className="bg-lendis-black">
      <div className="container mx-auto">
        <Carousel
          carousels={[
            {
              html: (
                <div className="flex flex-wrap items-center justify-center text-white">
                  <div className="pt-24 w-full">
                    <Image src="website/01_wepzur.png" alt="wepzur" className="mx-auto h-64" />
                  </div>
                  <div className="flex justify-center mx-auto pt-6 text-center w-full ">
                    <p
                      className="bg-no-repeat py-10 text-2xl text-center md:w-160 mx-16"
                      style={{ backgroundImage: `url(${quote})` }}
                    >
                      „Wir können den Service von Lendis nur empfehlen“
                    </p>
                  </div>
                </div>
              ),
            },

            {
              html: (
                <div className="flex flex-wrap items-center justify-center text-white">
                  <div className="pt-24 w-full">
                    <Image src="website/02_uzorog.png" alt="uzorog" className="mx-auto h-64" />
                  </div>
                  <div className="flex justify-center mx-auto pt-6 text-center w-full ">
                    <p
                      className="bg-no-repeat py-10 text-2xl text-center md:w-160 mx-16"
                      style={{ backgroundImage: `url(${quote})` }}
                    >
                      „Super Service, problemloser Aufbau, hilfsbereite Mitarbeiter. Wärmstens zu
                      empfehlen!“
                    </p>
                  </div>
                </div>
              ),
            },
            {
              html: (
                <div className="flex flex-wrap items-center justify-center text-white">
                  <div className="pt-24 w-full">
                    <Image src="website/03_v6fzur.png" alt="Enge" className="mx-auto h-64" />
                  </div>
                  <div className="flex justify-center mx-auto pt-6 text-center w-full ">
                    <p
                      className="bg-no-repeat py-10 text-2xl text-center md:w-160 mx-16"
                      style={{ backgroundImage: `url(${quote})` }}
                    >
                      „Enge, persönliche Zusammenarbeit. Die Möbel sind Klasse!“
                    </p>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default ConsumerCarousel
